<template>
  <div class="py-4">
    <!-- 最新/熱門/特色 遊戲 header -->
    <div class="d-flex justify-center flex-column align-center">
      <span class="font-weight-bold text-title">{{ $t(`game.${data.type}`) }}</span>
      <!-- 底線 -->
      <svg
        class="mt-1"
        width="68"
        height="4"
        viewBox="0 0 68 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="68"
          height="4"
          rx="2"
          fill="url(#87qqsuvi7a)"
        />
        <defs>
          <linearGradient
            id="87qqsuvi7a"
            x1="-3.803"
            y1="0"
            x2="30.196"
            y2="37.907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E6CE71" />
            <stop
              offset=".237"
              stop-color="#BC954D"
            />
            <stop
              offset=".513"
              stop-color="#A57539"
            />
            <stop
              offset=".779"
              stop-color="#C09A50"
            />
            <stop
              offset="1"
              stop-color="#E6CE71"
            />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <!-- slider -->
    <swiper
      v-if="data.data"
      ref="gamesSlider"
      :options="swiperOption"
      class="mySwiper py-0 mt-5"
    >
      <swiper-slide
        v-for="(game,index) in data.data.games"
        :key="index"
      >
        <div
          class="cursor-pointer"
          @click="goGame(index, game.game_code)"
        >
          <div class="rounded">
            <div class="games-item rounded pa-1">
              <v-img
                :src="baseImgUrl + game.game.image"
                cover
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div
      v-else
      class="text-center mt-5 caption"
    >
      empty
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '../../../../utils/resUtils'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 2.6,
      spaceBetween: -20,
      centeredSlides: true,
      initialSlide: 2,
    },

    // 遊戲資料
    items: [
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
      { image: 'https://picsum.photos/167/102' },
    ],
  }),

  computed: {
    ...mapGetters(['baseImgUrl']),
    ...mapState({
      isLogin: state => state.user.isLogin,
    }),
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
    ]),

    setActiveSlider(index) {
      this.$refs.gamesSlider.$swiper.slideTo(index)
    },

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl
      this.set_progress_status(false)

      window.open(gameUrl, '_blank')
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },

    async goGame(index, gameCode) {
      this.setActiveSlider(index)

      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      this.set_progress_status(true)
      const res = await this.get_game_url({ gamecode: gameCode })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  transform: scale(.6);
  filter:opacity(.4);
}

.swiper-slide-active {
  // background: url('./img/gamesBorder.png');
  background: #1e1e1e;
  border-radius: 4px;
  border: 2px solid #E6CE71;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(1);
  filter:opacity(1);
}
</style>
